.wrapper{
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px dashed var(--selected-theme-main-color);
  h2{
    font-family: var(--font-base);
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 1rem;
    text-transform: capitalize;
    color: var(--selected-theme-main-color);
    position: relative;
    display: flex;
    flex: 1;
  }
  span{
    svg{
      color: var(--selected-theme-main-color);
    }
  }
}