.navbar {
  font-family: var(--font-base);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: var(--selected-theme-nav-background-color);
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 0px 20px;
    &__logo {
      color: var(--selected-theme-main-color);
      cursor: pointer;
      font-size: 2rem;
      flex-grow: 1;
    }
    &__menu {
      display: flex;
      list-style: none;
      text-align: center;
      &__item {
        line-height: 40px;
        margin-right: 1.5rem;
        font-size: 1.6rem;
        &::after {
          content: "";
          display: block;
          height: 3px;
          width: 0;
          background: transparent;
          transition: width 0.5s ease;
        }
        &:hover::after {
          width: 100%;
          background: var(--selected-theme-main-color);
        }
        &__links {
          color: var(--selected-theme-main-color);
          text-decoration: none;
          padding: 0.5rem 1rem;
        }
      }
      &__item:last-child{
        margin: 0px;
      }
    }
  }
}

.nav-icon{
  display: none;
}

@media only screen and (max-width:768px) {
  .navbar__container__menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 80px;
    left:-110%;
    opacity: 1;
    transition: all 0.5s ease;
    &.active{
      background: #181818;
      left: 0;
      opacity: 1;
      z-index: 1;
      transition: all 0.5s ease;
    }
  }
  .navbar__container__menu__item{
    border-bottom: 1px solid #282828;
    margin: 0;
    padding: 20px 0;
  }
  .nav-icon{
    display: block;
    cursor: pointer;
    color: var(--selected-theme-main-color);
  }
}