.portfolio{
  &__content{
    padding: 20px;
    width: 100%;
    &__filter{
      display: inline-block;
      margin: 0 auto;
      border-radius: 26px;
      border: 1px dashed var(--selected-theme-main-color);
      line-height: 0;
      text-align: center;
      li{
        font-family: var(--font-base);
        font-size: 16px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        color: var(--selected-theme-main-color);
        text-transform: uppercase;
        padding: 20px;
        transition: all 0.4s ease;
        &.active{
          background: var(--selected-theme-main-color);
          color: var(--selected-theme-sub-text-color);
          font-weight: 600;
          border-radius: 26px;
        }
      }
    }
    &__cards{
      display: grid;
      grid-template-columns: repeat(3,1fr);
      gap: 1rem;
      margin-top: 10px;
      &__item{
        height: 366px;
        cursor: pointer;
        position: relative;
        &:hover .overlay{
          opacity: 1;
        }
        &__img-wrapper{
          height: 100%;
          a{
            height: 100%;
            img{
              height: inherit;
              object-fit: cover;
              width: 100%;
            }
          }
        }
        .overlay{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background-color: var(--selected-theme-main-color);
          div{
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            p{
              font-family: var(--font-base);
              color: var(--selected-theme-sub-text-color);
              margin: 0;
              font-weight: 500;
              font-size: 2rem;
              position: relative;
              overflow: hidden;
              animation: typingEffect 1s steps(30,end);
              white-space: nowrap;
            }
            button{
              cursor: pointer;
              margin-top: 15px;
              padding: 10px 70px;
              font-size: 2rem;
              background: transparent;
              color: var(--selected-theme-sub-text-color);
              border: 1.5px solid var(--selected-theme-sub-text-color);
              border-radius: 4px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

@keyframes typingEffect{
  from{
    width: 0;
  }
  to{
    width: 100%;
  }
}

@media only screen and (max-width: 480px){
  .portfolio__content__filter > li{
    font-size: 11px;
  }
  .portfolio__content__cards{
    grid-template-columns: repeat(1,1fr);
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px){
  .portfolio__content__filter > li{
    font-size: 14px;
  }
  .portfolio__content__cards{
    grid-template-columns: repeat(2,1fr);
  }
  .portfolio__content__cards__item div p{
    font-size: 2.5rem;
  }
}