.theme-wrapper{
  width: 180px;
  position: fixed;
  top: 22%;
  right: -180px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  transition: all 0.5s ease;
  &.active{
    right: 0;
  }
  &__toggle-icon{
    position: absolute;
    top: -1px;
    left:-40px;
    height: 40px;
    width: 40px;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-right: none;
    cursor: pointer;
    svg{
      animation-name: spin;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      color: var(--selected-theme-main-color);
    }
  }
  &__menu{
    text-align: center;
    padding: 20px 10px;
    h4{
      font-family: var(--font-base);
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: #222;
      margin-bottom: 16px;
    }
    ul{
      display: flex;
      gap: 2px;
      li{
        list-style-type: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
}

@keyframes spin {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}