.skills{
  &__content-wrapper{
    display: flex;
    padding: 20px;
    gap: 20px;
    &__inner-content{
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      &__category-text{
        display: inline-block;
        color: var(--selected-theme-sub-text-color);
        font-size: 22px;
        font-weight: 600;
        line-height: 1rem;
        letter-spacing: 0.5px;
        font-family: var(--font-base);
        position: relative;
        text-transform: uppercase;
        padding: 5px 10px;
        border-bottom: 2px solid var(--selected-theme-main-color);
      }
      div{
        width: 100%;
      }
      .progressbar-wrapper{
        padding-top: 50px;
        p{
          font-size: 1.6rem;
          font-weight: 500;
          margin: 0 0 10px 0px;
          text-transform: uppercase;
          font-family: var(--font-base);
          color: var(--selected-theme-sub-text-color);
        }
      }
      &__category-text::before,&__category-text::after{
        position: absolute;
        content: '';
        width: 2px;
        height: 5px;
        background-color: var(--selected-theme-main-color);
        bottom: -1px;
      }
      &__category-text::before{
        left: 0;
      }
      &__category-text::after{
        right: 0;
      }
    }
  }
}

@media only screen and (max-width:768px) {
  .skills{
    &__content-wrapper{
      flex-direction: column;
    }
  }
}