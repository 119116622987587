.home{
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__text-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    h1{
      color: var(--selected-theme-main-color);
      margin: 0;
      font-weight: 400;
      font-size: 8rem;
      line-height: 10rem;
      position: relative;
      font-family: var(--font-base);
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      animation: typingEffect 2.5s steps(30,end);
    }
  }
  .contact-me{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
    &__buttons-wrapper{
      display: flex;
      gap: 10px;
      button, a{
        font-family: var(--font-base);
        cursor: pointer;
        padding: 10px 70px;
        font-size: 2rem;
        background: transparent;
        color: var(--selected-theme-main-color);
        border: 1.5px solid var(--selected-theme-main-color);
        border-radius: 4px;
        font-weight: 500;
        &:hover{
          color: #000000;
          background: var(--selected-theme-main-color);
        }
      }
    }
    &__socials-wrapper{
      display: flex;
      gap: 10px;
      svg{
        color: var(--selected-theme-main-color);
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .home__text-wrapper h1{
    font-size: 3rem;
    line-height: 5rem;
  }
}

@media only screen and (min-width:551px) and (max-width: 768px) {
  .home__text-wrapper h1{
    font-size: 4rem;
    line-height: 6rem;
  }
}

@media only screen and (min-width:769px) and (max-width: 990px) {
  .home__text-wrapper h1{
    font-size: 6rem;
    line-height: 8rem;
  }
}

@keyframes typingEffect {
  from{
    width: 0;
  }
  to{
    width: 100%;
  }
}