.App{
  height: 100%;
  &__navbar-wrapper{
    position: absolute;
    width: 100%;
    top: 0;
  }
  &__main-content-wrapper{
    height: calc(100% - 80px);
    position: absolute;
    width: 100%;
    margin-top: 80px;
  }
}