.contact{
  &__content{
    padding: 20px;
    &__header-text{
      display: inline-block;
      color: var(--selected-theme-sub-text-color);
      font-size: 22px;
      font-weight: 600;
      line-height: 1rem;
      letter-spacing: 0.5px;
      position: relative;
      text-transform: uppercase;
      padding: 5px 10px;
      border-bottom: 2px solid var(--selected-theme-main-color);
    }
    &__header-text::before,&__header-text::after{
      position: absolute;
      content: '';
      width: 2px;
      height: 5px;
      background-color: var(--selected-theme-main-color);
      bottom: -1px;
    }
    &__header-text::before{
      left: 0;
    }
    &__header-text::after{
      right: 0;
    }
    &__form{
      margin-top: 30px;
      &__controlsWrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        margin-bottom: 30px;
        div{
          position: relative;
          .inputName,.inputEmail,.inputDescription{
            width: 100%;
            border: 2px solid var(--selected-theme-main-color);
            padding: 15px 10px;
            transition: all .5s ease;
            position: relative;
            z-index: 1;
            background: transparent;
            color: var(--selected-theme-main-color);
            font-family: var(--font-base);
          }
          .nameLabel,.emailLabel,.descriptionLabel{
            position: absolute;
            font-weight: 500;
            top: 7px;
            left: 12px;
            z-index: 0;
            color: var(--selected-theme-main-color);
            font-size: 16px;
            font-family: var(--font-base);
          }
          .inputName:focus,.inputEmail:focus,.inputDescription:focus{
            outline: none;
          }
          .inputName:focus + .nameLabel,
          .inputEmail:focus + .emailLabel,
          .inputDescription:focus + .descriptionLabel{
            font-size: 18px;
            top: -25px;
            left: 0;
          }
        }
      }
      button{
        cursor: pointer;
        padding: 10px 70px;
        font-size: 2rem;
        background: transparent;
        color: var(--selected-theme-main-color);
        border: 1.5px solid var(--selected-theme-main-color);
        border-radius: 4px;
        font-weight: 500;
        font-family: var(--font-base);
        &:hover{
          color: #000000;
          background-color: var(--selected-theme-main-color);
        }
      }
    }
  }
}

@media only screen and (max-width:768px) {
  .contact__content__form{
    text-align: center;
    &__controlsWrapper{
      grid-template-columns: repeat(1,1fr);
      gap: 45px;
    }
  }
}